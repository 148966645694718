<template>
  <div>
    <vx-card>
      <vs-button
        class="flex ml-auto"
        @click="$router.push({ name: 'partner-pages-create' })"
        >Add Page</vs-button
      >
      <vs-table :max-items="descriptionItems[0]" pagination :data="pages">
        
        <template slot="thead">
          <vs-th>
            Title
          </vs-th>

          <vs-th>
            Company Name
          </vs-th>
          <vs-th>
            Action
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(page, indextr) in data">
            <vs-td :data="page.title">
              {{ page.title }}
            </vs-td>

            <vs-td :data="page.companyName">
              {{ page.companyName }}
            </vs-td>

            <vs-td :data="page._id">
              <feather-icon
                icon="EditIcon"
                svgClasses="h-4 w-4"
                class="ml-1"
                @click="handleEdit(page._id)"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      descriptionItems: [10, 25, 40],
      pages: []
    };
  },
  methods: {
    ...mapActions("page", ["fetchPagesByPartnerId"]),
    async getPages(partnerId) {
      await this.fetchPagesByPartnerId(partnerId)
        .then(result => {
          this.pages = result.data.data;
        })
        .catch(err => {});
    },
    handleEdit(id) {
      this.$router.push({
        name: "partner-pages-edit",
        params: { id: id }
      });
    }
  },
  mounted() {
    this.getPages(this.partnerId);
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user._id;
    }
  }
};
</script>
